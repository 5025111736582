<template>
    <div class="text-white">
        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Top part, main content */}}
        {{/* * */}}
        <div class="theme-dark min-h[600px] lg:min-h-[700px] bg-black relative z-0">
            <MainHeroBackground />

            <div class="container relative z-10">
                <div class="flex items-center justify-center pt-9 max-w-72 sm:max-w-xl mx-auto">
                    <TabButton
                        :is-active="activeChannelTab === 'jazzylive'"
                        desktop-label-prefix="Jazzy"
                        label="Live"
                        class="w-32"
                        @click.native="$store.commit('channel/setActiveChannelTab', 'jazzylive')"
                    />
                    <TabButton
                        :is-active="activeChannelTab === 'jazzysoul'"
                        desktop-label-prefix="Jazzy"
                        label="Soul"
                        class="w-32"
                        @click.native="$store.commit('channel/setActiveChannelTab', 'jazzysoul')"
                    />
                    <TabButton
                        :is-active="activeChannelTab === 'jazzygroove'"
                        desktop-label-prefix="Jazzy"
                        label="Groove"
                        class="w-32"
                        @click.native="$store.commit('channel/setActiveChannelTab', 'jazzygroove')"
                    />
                    <TabButton
                        :is-active="activeChannelTab === 'jazzycool'"
                        desktop-label-prefix="Jazzy"
                        label="Cool"
                        class="w-32"
                        @click.native="$store.commit('channel/setActiveChannelTab', 'jazzycool')"
                    />
                </div>

                <Transition name="fade-hero" mode="out-in">
                    <MainHeroContentPanel
                        v-if="activeChannelTab === 'jazzylive'"
                        key="content-panel-jazzylive"
                        channel="jazzylive"
                    />
                    <MainHeroContentPanel
                        v-else-if="activeChannelTab === 'jazzysoul'"
                        key="content-panel-jazzysoul"
                        channel="jazzysoul"
                    />
                    <MainHeroContentPanel
                        v-else-if="activeChannelTab === 'jazzygroove'"
                        key="content-panel-jazzygroove"
                        channel="jazzygroove"
                    />
                    <MainHeroContentPanel
                        v-else-if="activeChannelTab === 'jazzycool'"
                        key="content-panel-jazzycool"
                        channel="jazzycool"
                    />
                </Transition>
            </div>

            <div
                v-if="$store.state.globals.isGenericErrorMessageVisible"
                class="absolute top-4 lg:top-[unset] lg:bottom-12 w-full px-4 flex justify-center z-20"
            >
                <div class="bg-black p-4 rounded-lg flex items-start">
                    <i class="pi pi-exclamation-triangle text-lg text-red-500 mr-4 translate-y-0.5"></i>
                    <div>
                        Sajnálattal értesítünk titeket, hogy oldalunkon technikai hiba lépett fel, dolgozunk a
                        javításon. Köszönjük a türelmeteket!
                    </div>
                </div>
            </div>

            <div
                class="absolute bottom-0 right-0 bg-contain bg-right-bottom bg-no-repeat h-5 w-60 md:w-96 -mb-1 md:-mb-2"
                :style="`background-image: url(${require('~/assets/images/decorators/zigzag_default.svg')})`"
            ></div>
        </div>
        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Bottom part, track list */}}
        {{/* * */}}
        <div class="relative theme-dark bg-grey-700 z-5">
            <div class="sm:container">
                <Transition name="fade-opacity" mode="out-in">
                    <MainHeroTracksBar
                        v-if="activeChannelTab === 'jazzylive'"
                        key="trackbar-jazzylive"
                        target="jazzylive"
                        :items="jazzyLiveFollowUpShows.data"
                    />
                    <MainHeroTracksBar
                        v-if="activeChannelTab === 'jazzysoul' && $store.state.channel.jazzysoul"
                        key="trackbar-jazzysoul"
                        target="jazzysoul"
                        :items="$store.state.channel.jazzysoul.song_history"
                    />
                    <MainHeroTracksBar
                        v-if="activeChannelTab === 'jazzygroove' && $store.state.channel.jazzygroove"
                        key="trackbar-jazzygroove"
                        target="jazzygroove"
                        :items="$store.state.channel.jazzygroove.song_history"
                    />
                    <MainHeroTracksBar
                        v-if="activeChannelTab === 'jazzycool' && $store.state.channel.jazzycool"
                        key="trackbar-jazzycool"
                        target="jazzycool"
                        :items="$store.state.channel.jazzycool.song_history"
                    />
                </Transition>
            </div>
        </div>
        <div class="text-center mt-8 sm:mt-10 pb-8 sm:pb-10 mx-4 lg:hidden border-b border-grey-200">
            <p-button to="/playlist" label="Korábban szólt számok" class="lg:absolute p-button-outlined ml-4">
                <template #icon-left>
                    <update-icon :size="18" />
                </template>
            </p-button>
        </div>
    </div>
</template>

<script>
import TabButton from '../../UI/Buttons/TabButton';
import MainHeroBackground from '../../Generic/MainHeroBackground';
import MainHeroContentPanel from './MainHeroContentPanel';
import MainHeroTracksBar from './MainHeroTracksBar';

export default {
    name: 'MainHero',
    components: {
        TabButton,
        MainHeroBackground,
        MainHeroContentPanel,
        MainHeroTracksBar,
    },
    computed: {
        activeChannelTab() {
            return this.$store.state.channel.activeChannelTab;
        },
        channels() {
            return this.$store.state.channel;
        },
        jazzyLiveFollowUpShows() {
            return this.$store.state.data.scheduleByDays.reduce(
                (acc, curr, i) => {
                    if ([3, 4].includes(i)) {
                        curr.schedule.forEach((scheduleItem) => {
                            if (
                                this.$dayjs(`${curr.date} ${scheduleItem.start}`).isBefore(this.$dayjs()) ||
                                acc.data.length === 3
                            ) {
                                return acc;
                            }

                            const payload = scheduleItem.show;
                            payload._interval = `${scheduleItem.start} - ${scheduleItem.end}`;
                            acc.data.push(payload);
                        });
                    }

                    return acc;
                },
                { data: [], key: this.$store.state.channel.currentJazzyLiveShowShID }
            );
        },
    },
};
</script>

<style scoped></style>
